import { ApolloError } from 'apollo-client'

export interface IGraphQLErrorExtensions {
  exception?: {
    invalidArgs: Record<string, string[]>
  }
}

/**
 * Transform a Graphql Error response into a key => errors structure
 */
export function getErrorFields (error?: ApolloError) {
  if (error && error.graphQLErrors) {

    return error.graphQLErrors.reduce((acc, err) => {
      const extensions: IGraphQLErrorExtensions | undefined = err?.extensions
      const invalidArgs = extensions?.exception?.invalidArgs
      if (!invalidArgs) return acc

      // invalidArgs example:
      // {
      //   myField: [
      //     'Something went horribly wrong!',
      //     'Something else not good!'
      //   ]
      // }
      for (const [ key, values ] of Object.entries(invalidArgs)) {
        const errors = acc[key] || []
        acc[key] = [ ...errors, ...values ]
      }

      return acc
    }, {} as Record<string, string[]>)
  }

  return {} as Record<string, string[]>
}
