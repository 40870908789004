import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import PasswordInput from '../components/form/PasswordInput'
import { setValue } from '../utils/form'

export interface ILoginProps {
  isLoading?: boolean
  handleSubmit: (data: { email: string, password: string }) => void
  errors?: {
    email?: string[]
    password?: string[]
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bodyMargin: {
      padding: '20px',
    },
    aligner: {
      width: '100%',
      margin: 'auto',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '30vw',
      },
    },
    loginBox: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '5px',
    },
    margin: {
      margin: theme.spacing(1),
      marginBottom: '30px',
    },
    submitButton: {
      margin: theme.spacing(1),
      marginLeft: 'auto',
      color: '#fff',
    },
    logo: {
      width: '400px',
      maxWidth: '100%',
      marginBottom: '50px',
    },
  })
)

export default function LoginComponent({ isLoading = false, handleSubmit, errors }: ILoginProps) {
  const [ email, setEmail ] = React.useState('')
  const [ password, setPassword ] = React.useState('')
  const styles = useStyles()

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    return handleSubmit({ email, password })
  }

  return (
    <div className={styles.bodyMargin}>
      <div className={styles.aligner}>
        <img src="https://image.meersports.nl/unsafe/400x0/meer-sports/meersports-logo-transparant.png" alt="logo Meer Sports" className={styles.logo} />
        <form onSubmit={onSubmit} className={styles.loginBox}>
          <TextField
            disabled={isLoading}
            error={!!errors?.email}
            required
            id="email"
            type="email"
            label="Email"
            className={styles.margin}
            value={email}
            onChange={setValue(setEmail)}
            margin="normal"
            helperText={errors?.email ? errors?.email?.[0] : ''}
          />

          <PasswordInput
            disabled={isLoading}
            error={!!errors?.password}
            formControlClass={styles.margin}
            onChange={setValue(setPassword)}
            value={password}
            helperText={errors?.password ? errors?.password?.[0] : ''}
          />

          <Button disabled={isLoading} type="submit" variant="contained" color="primary" className={styles.submitButton}>Login</Button>
        </form>
      </div>
    </div>
  )
}
