import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { setValue } from '../../utils/form'
import { Link } from 'react-router-dom'
import { routes } from '../../routes'

export interface IExerciseClassData {
  id?: string
  name: string
  defaultDuration: number
}

export interface IExerciseClassFormProps {
  isLoading?: boolean
  handleSubmit: (data: IExerciseClassData) => void
  errors?: {
    name?: string[]
    defaultDuration?: string[]
  },
  initialData?: IExerciseClassData
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '20px',
      maxWidth: '800px',
      position: 'relative',
      marginBottom: '20px',
    },
    margin: {
      margin: '20px',
    },
    button: {
      margin: theme.spacing(1),
      position: 'absolute',
      right: '10px',
      bottom: '10px',
    },
    menu: {
      width: 200,
    },
    inputLeft: {
      clear: 'left',
    },
    errorP: {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
      padding: '10px 24px',
      alignItems: 'center',
      borderRadius: '5px',
    },
  }),
)

export default function ExerciseClassFrom({
  handleSubmit,
  errors,
  isLoading,
  initialData,
}: IExerciseClassFormProps) {
  const classes = useStyles()

  const [ name, setName ] = React.useState(initialData?.name || '')
  const [ defaultDuration, setDefaultDuration ] = React.useState(initialData?.defaultDuration || 50 * 60)
  const handleDefaultDurationChange = (event: any) => setDefaultDuration(
    Math.max(Math.min(event?.target?.value, 1440), 1) * 60
  )
  const getDefaultDuration = () => Math.round(defaultDuration / 60)

  const isNew = !initialData

  const onSubmit = (event: any) => {
    event.preventDefault()
    handleSubmit({ name, defaultDuration })
  }

  return (
    <div className="create-class">
      <Link to={routes['list-exerciseclasses'].path}>
        &#8592; Terug naar lessenoverzicht
      </Link>
      <h1>{isNew ? 'Nieuwe Les toevoegen' : `Les ${initialData?.name} wijzigen`}</h1>
      <form onSubmit={onSubmit}>

        <Paper className={classes.paper}>
          <TextField
            disabled={isLoading}
            required
            id="name"
            label="Naam"
            className={classes.margin}
            value={name}
            onChange={setValue(setName)}
            margin="normal"
            error={!!errors?.name}
            helperText={errors?.name ? errors?.name?.[0] : ''}
          />

          <TextField
            disabled={isLoading}
            id="defaultDuration"
            label="Standaard duur"
            className={classes.margin}
            type="number"
            value={getDefaultDuration()}
            onChange={handleDefaultDurationChange}
            margin="normal"
            error={!!errors?.defaultDuration}
            helperText={errors?.defaultDuration ? errors?.defaultDuration?.[0] : 'minuten'}
            inputProps={{ min: '5', max: '1440', step: '5' }}
          />
        </Paper>

        <Paper className={classes.paper}>
          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            color="primary"
          >{isNew ? 'Les toevoegen' : 'Les wijzigen'}</Button>
        </Paper>
      </form>
    </div>
  )
}
