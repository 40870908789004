import React, { useEffect } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { getLastWeekday, format } from '../../utils/date'
import { GET_CLASS_ACTIVITIES } from '../../graphql/classActivities'
import { useQuery, useLazyQuery } from 'react-apollo'
import addDays from 'date-fns/addDays'

interface ScheduleDayProps {
  date: Date
}

function ScheduleDay({ date }: ScheduleDayProps) {
  return (
    <div className="class-schedule-day">
      {format(date, 'd MMMM')}
    </div>
  )
}

interface ClassScheduleProps {
  firstDay: Date
  numberOfDays?: number
}

function ClassSchedule({ firstDay, numberOfDays = 7 }: ClassScheduleProps) {
  const days = []
  for (let i = 0; i < numberOfDays; i++) {
    days.push(new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + i))
  }

  return (
    <div className="class-schedule">
      {days.map(day => <ScheduleDay key={day.getTime()} date={day} />)}
    </div>
  )
}

export default function ClassActivitiesList() {
  const [ selectedDate, setSelectedDate ] = React.useState(getLastWeekday())
  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date) setSelectedDate(date)
  }
  const numberOfDays = 7

  const [ getData, { loading, error, data } ] = useLazyQuery(GET_CLASS_ACTIVITIES, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    console.info('Getting data')
    getData({ variables: { find: {
      dateAfter: selectedDate,
      dateBefore: addDays(selectedDate, numberOfDays)
    } } })
  }, [ getData, selectedDate ])

  return (
    <div className="class-activities-list">
      <h1>Lesrooster</h1>
      <pre>{JSON.stringify(data, null, 2)}</pre>
      {error
        ? <span>Er is iets mis gegaan.</span>
        : (
          loading
            ? <div>Laden...</div>
            : (<>
              <DatePicker value={selectedDate} onChange={handleDateChange} format="d MMMM" autoOk />
              <ClassSchedule firstDay={selectedDate} numberOfDays={numberOfDays}/>
            </>)
        )
      }

    </div>
  )
}
