import React from 'react'
import LoginComponent from './LoginComponent'
import { LOGIN } from '../graphql/auth'
import { useMutation } from '@apollo/react-hooks'
import { getErrorFields } from '../utils/apolloError'
import { useAuth } from '../components/providers/AuthProvider'
import { noop } from '../utils/fn'

export default function LoginData() {
  const { login } = useAuth()

  const onCompleted = (data: any) => {
    if (data?.login) {
      login(data.login)
    }
  }

  const [ doLogin, { error, loading } ] = useMutation(LOGIN, { errorPolicy: 'all', onCompleted })

  const handleSubmit = (data: { email: string, password: string }) => {
    doLogin({ variables: data }).catch(noop)
  }

  return <LoginComponent isLoading={loading} handleSubmit={handleSubmit} errors={getErrorFields(error)}/>
}
