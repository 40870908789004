import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import ApolloClient from 'apollo-client'
// import ApolloLinkTimeout from 'apollo-link-timeout'
import { WebSocketLink } from 'apollo-link-ws'
import {
  SubscriptionClient,
  // Operation
} from 'subscriptions-transport-ws'
import { split, ApolloLink } from 'apollo-link'
// import { getMainDefinition } from 'apollo-utilities'
// import { setContext } from 'apollo-link-context'
// import ApolloWebsocketStatus from './ApolloWebsocketStatus'
import { onError } from 'apollo-link-error'
import { tokenStorage } from '../components/providers/AuthProvider'

// const timeoutLink = new ApolloLinkTimeout(10000)

/**
 * HTTP
 */

// Create an http link
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_MEER_SPORTS_API_URL,
})

// // Add authentication header to all http requests
// const authLink = setContext((_, { headers }) => {
//   // Get the authentication token
//   const token = tokenStorage.get()

//   // Return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: `Bearer ${token}` || '',
//     },
//   }
// })

// const authenticatedHttpLink = authLink.concat(httpLink)


/**
 * WEBSOCKET
 */

// create Websocket client
export const wsClient = new SubscriptionClient(process.env.REACT_APP_MEER_SPORTS_API_WS || '', {
  reconnect: true,
  connectionParams: () => {
    const token = tokenStorage.get()
    return { authToken: token }
  },
  lazy: true, // connect when needed
})

// Overwrite connectionParams function
// When someone login, the send connection params should change
// wsClient.connectionParams = getConnectionParams

// Create a WebSocket link
const wsLink = new WebSocketLink(wsClient)

// Keep a tab on the websocket status
// const wsStatus = new ApolloWebsocketStatus(wsClient)

/**
 * Check if the current operations has a GraphQL subscription directive
 * @param {object} operation Apollo operation object
 * @param {object} operation.query Query object
 * @param {array}  operation.query.definitions Apollo GraphQL query definitions
 */
// eslint-disable-next-line no-unused-vars
// const hasSubscriptionOperation = ({ query: { definitions } }) => {
//   return definitions.some(({ kind, operation }) => {
//     return kind === 'OperationDefinition' && operation === 'subscription'
//   })
// }

// const hasOpenWebsocketConnection = () => wsStatus.isOpen()

// Using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const splitLink = split(
  // split based on whether the websocket connection is active
  (operation): boolean => {
    return [ 'Login', 'CheckToken', 'Logout' ].includes(operation.operationName)
    // const token = tokenStorage.get()
    // return token && hasOpenWebsocketConnection
  },
  httpLink,
  wsLink,
)

// const splitLinkWithTimeout = timeoutLink.concat(splitLink)

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    // if (graphQLErrors) {
    //   sendToLoggingService(graphQLErrors);
    // }
    // if (networkError) {
    //   logoutUser();
    // }
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => console.info(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ))
    }

    if (networkError) {
      console.info('[Network error]:', networkError)
    }
  }),
  splitLink,
])

/**
{
  cacheRedirects: {
    Query: {
      exerciseClass: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'ExerciseClass', id: args.id })
    },
  },
}
 */
export const cache = new InMemoryCache();

const client = new ApolloClient({
  // uri: process.env.REACT_APP_MEER_SPORTS_API_URL || '',
  link,
  cache,
})

export default client

/**
 * Use same data on detail page as on list page:
 *
 * const cache = new InMemoryCache({
 *   cacheRedirects: {
 *     Query: {
 *       book: (_, args, { getCacheKey }) =>
 *         getCacheKey({ __typename: 'Book', id: args.id })
 *     },
 *   },
 * })
 */
