/**
 * Left pad a string with a character
 *
 * @param {string}  str    String to pad
 * @param {integer} length Lenght to pad string to
 * @param {string}  char   Char used to pad string
 */
export function padLeft(str: string, length: number, char: string = ' ') {
  return (str + '').padStart(length, char)
}
