import React, { PropsWithChildren } from 'react'
import jwtDecode from 'jwt-decode'
import { useMutation } from 'react-apollo'
import { CHECK_TOKEN } from '../../graphql/auth'

const STORAGE_TOKEN_KEY = 'meer_sports_auth_token'

/**
 * Storage access object
 */
let tokenCache: string | null = null
export const tokenStorage = {
  get: () => {
    if (tokenCache === null) {
      tokenCache = window.localStorage.getItem(STORAGE_TOKEN_KEY)
    }

    return tokenCache
  },
  set: (token: string) => {
    window.localStorage.setItem(STORAGE_TOKEN_KEY, token)
    tokenCache = token
  },
  remove: () => {
    window.localStorage.removeItem(STORAGE_TOKEN_KEY)
    tokenCache = null
  },
}

/**
 * Enum with all authentication statusses
 */
export enum LoginStatus {
  Unauthenticated = 'UNAUTHENTICATED',
  Loading = 'LOADING',
  Authenticated = 'AUTHENTICATED',
}

export interface ITokenData {
  userId: string
  email: string
  exp: number // number of seconds token is valid
}

export interface IAuthContext {
  data: ITokenData | null
  token: string | null
  status: LoginStatus
  login: (token: string) => void
  logout: () => void
}

/**
 * Decode the JWT token
 */
export const decodeToken = (token: string | null) => {
  if (!token) return null

  let data = null
  if (token) {
    try {
      data = jwtDecode<ITokenData>(token)
    } catch (error) {
      data = null
    }
  }

  return data
}

/**
 * Check if token is still valid with TokenData
 */
export const isTokenExpired = (data: ITokenData | null) => {
  const now = new Date().getTime()
  return !data?.exp || (data.exp * 1000) < now
}

/**
 * Context: Auth context object
 */
export const AuthContext = React.createContext<IAuthContext>({
  data: null,
  token: tokenStorage.get(),
  status: LoginStatus.Unauthenticated,
  login: () => {},
  logout: () => {},
})
AuthContext.displayName = 'AuthContext'

export interface IAuthProviderProps {

}

/**
 * Provider: Inject context into component tree
 */
export const AuthProvider = ({ children }: PropsWithChildren<IAuthProviderProps>) => {
  const [ token, setToken ] = React.useState(tokenStorage.get())
  const [ status, setStatus ] = React.useState(LoginStatus.Unauthenticated)
  const [ data, setData ] = React.useState<IAuthContext['data']>(null)
  const [ doCheckToken ] = useMutation(CHECK_TOKEN, { errorPolicy: 'all' })

  const login = (token: string) => {
    const data = decodeToken(token)
    if (isTokenExpired(data)) return logout()

    setToken(token)
    tokenStorage.set(token)
    setData(data)
    setStatus(LoginStatus.Authenticated)
  }

  const logout = () => {
    setToken(null)
    tokenStorage.remove()
    setData(null)
    setStatus(LoginStatus.Unauthenticated)
  }

  React.useEffect(() => {
    // Logout when token has expired
    const data = decodeToken(token)
    if (isTokenExpired(data)) {
      // add snackbar message - token has expired
      return logout()
    }

    // Check token is valid
    if (token && status === LoginStatus.Unauthenticated) {
      setStatus(LoginStatus.Loading)

      // Check if token is still valid
      doCheckToken({ variables: { token } })
        .then((res: any) => res?.data?.checkToken ? login(token) : logout())
        .catch((error) => {
          console.info('doCheckToken ERROR', error)
          logout()
        })
    }

    // Should only run at the start of the app
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContext.Provider
      value={{
        data,
        token,
        status,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

/**
 * Hook: Use auth context values
 */
export function useAuth() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

/**
 * Hook: Check if user has been logged in
 */
export function useIsAuthenticated() {
  const { status } = useAuth()
  return status === LoginStatus.Authenticated
}
