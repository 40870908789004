import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import SimpleError from '../../components/error/SimpleError'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Zoom from '@material-ui/core/Zoom'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import { sortOnString, sortOnDate, sortOnNumber, sortOn, sortReverse } from '../../utils/sort'
import { asDate } from '../../utils/date'
import { IExerciseClass, GET_EXERCISE_CLASSES, EXERCISE_CLASSES_SUBSCRIPTION } from '../../graphql/exerciseClasses'
import { routes } from '../../routes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      // minWidth: 700,
      width: '100%',
      // overflowX: 'scroll',
    },
    zoom: {
      // position: 'fixed',
      // right: '20px',
      // bottom: '20px',
    },
    fab: {
      // position: 'relative',
      position: 'fixed',
      right: '20px',
      bottom: '20px',
    },
    row: {
      cursor: 'pointer',
    },
  }),
)

const sorters = {
  id: sortOn<IExerciseClass>('id', sortOnNumber),
  name: sortOn<IExerciseClass>('name', sortOnString),
  defaultDuration: sortOn<IExerciseClass>('defaultDuration', sortOnNumber),
  createdAt: sortOn<IExerciseClass>('createdAt', sortOnDate),
  updatedAt: sortOn<IExerciseClass>('updatedAt', sortOnDate),
}

const headers: Array<{ label: string, id: keyof IExerciseClass | null }> = [
  { label: 'Naam', id: 'name' },
  { label: 'Duur (min.)', id: 'defaultDuration' },
  { label: 'Bijgewerkt', id: 'updatedAt' },
  { label: '', id: null },
]

export interface ListExerciseClassesComponentProps {
  exerciseClasses: IExerciseClass[]
  handleDelete?: (exerciseClass: IExerciseClass) => void
  isLoading?: boolean
}

export function ListExerciseClassesComponent({ exerciseClasses, handleDelete, isLoading }: ListExerciseClassesComponentProps) {
  const classes = useStyles()
  const history = useHistory()
  const [ order, setOrder ] = React.useState<'asc' | 'desc'>('asc')
  const [ orderBy, setOrderBy ] = React.useState<keyof IExerciseClass>('id')
  const handleAddButtonClick = () => history.push(routes['create-exerciseclass'].path)

  exerciseClasses.sort(sortReverse(sorters[orderBy], order === 'asc'))

  const setSorting = (prop: keyof IExerciseClass) => () => {
    if (orderBy === prop) {
      return setOrder(order === 'asc' ? 'desc' : 'asc')
    }

    setOrder('asc')
    setOrderBy(prop)
  }

  return (
    <div className="exercise-classes">
      <h1>Lessen</h1>

      {isLoading
      ? <Typography>Laden...</Typography>
      : <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map(({ label, id }, i) => {
              return (
                <TableCell key={i}>
                  <Tooltip
                    title="Sorteren"
                    placement='bottom-start'
                    enterDelay={300}
                  >
                    {id
                    ? <TableSortLabel
                        active={orderBy === id}
                        direction={order}
                        onClick={setSorting(id)}
                      >{label}</TableSortLabel>
                    : <React.Fragment>{label}</React.Fragment>}
                  </Tooltip>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>

        <TableBody>
            {exerciseClasses.map(({ id, name, defaultDuration, updatedAt }) => {
              const handleRowClick = () => history.push(routes['update-exerciseclass'].path.replace(':id', id))

              return (
                <TableRow className={classes.row} key={id}>
                  <TableCell onClick={handleRowClick}>{name}</TableCell>
                  <TableCell onClick={handleRowClick}>{Math.round(defaultDuration / 60)}</TableCell>
                  <TableCell onClick={handleRowClick}>{asDate(updatedAt)}</TableCell>
                  <TableCell onClick={handleRowClick}>&nbsp;</TableCell>
                  {/* <RemoveCell
                    mutation={REMOVE_EXERCISE_CLASS}
                    variables={{ id }}
                    update={(cache, { data: { destroyExerciseClass = {} } }) => {
                      const { classes } = cache.readQuery({ query: GET_EXERCISE_CLASSES })
                      cache.writeQuery({
                        query: GET_EXERCISE_CLASSES,
                        data: { classes: classes.filter(c => c.id !== id) },
                      })
                    }}
                    key={id}
                    modalTitle={`Weet je zeker dat je '${name}' wilt verwijderen?`}
                    modalOk="Verwijderen"
                    modalCancel="Annuleren"
                    onCompleted={this.createHandleRemoveCellOnCompleted(name)}
                    onError={this.createHandleRemoveCellOnError(name)}
                  /> */}
                </TableRow>
              )
            })}
        </TableBody>
      </Table>}

      <Zoom in={true} unmountOnExit >
        <Fab
          className={classes.fab}
          color="primary"
          onClick={handleAddButtonClick}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </div>
  )
}

export default function ListExerciseClasses() {
  const {
    loading,
    error,
    data,
    subscribeToMore,
  } = useQuery(GET_EXERCISE_CLASSES, { errorPolicy: 'all', fetchPolicy: 'cache-and-network' })

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: EXERCISE_CLASSES_SUBSCRIPTION,
      updateQuery: (prev: { exerciseClasses: IExerciseClass[] }, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const { doc, event } = subscriptionData.data.exerciseClassModified

        switch (event) {
          case 'create':
            if (prev.exerciseClasses.find(c => c.id === doc.id)) return prev
            return { ...prev, exerciseClasses: [...prev.exerciseClasses, doc] }
          case 'update':
            return { ...prev, exerciseClasses: prev.exerciseClasses.map(c => (c.id === doc.id) ? doc : c) }
          case 'delete':
            return { ...prev, exerciseClasses: prev.exerciseClasses.filter(c => c.id !== doc.id) }
          default:
            throw new Error('Unknown event in EXERCISE_CLASSES_SUBSCRIPTION')
        }
      },
    })

    return () => unsubscribe()
  }, [ subscribeToMore ])

  if (error) return <SimpleError error={error} />

  return <ListExerciseClassesComponent
    isLoading={loading}
    exerciseClasses={data?.exerciseClasses || []}
  />
}

// class ListExerciseClassesComponenst extends React.Component {
//   createSortHandler = property => event => {
//     this.props.onRequestSort(event, property)
//   }

//   createHandleRemoveCellOnCompleted = name => () => {
//     this.props.enqueueSnackbar(`Les '${name}' succesvol verwijderd!`, { variant: 'success' })
//   }

//   createHandleRemoveCellOnError = name => () => {
//     this.props.enqueueSnackbar(`Er is iets fout gegaan. Les '${name}' kon niet worden verwijderd.`, { variant: 'error' })
//   }

//   render() {
//     const { classes, theme, order, orderBy } = this.props
//     const sortOrder = order === 'desc' ? -1 : 1

//     const transitionDuration = {
//       enter: theme.transitions.duration.enteringScreen,
//       exit: theme.transitions.duration.leavingScreen,
//     }

//     return (
//       <Query
//         query={GET_EXERCISE_CLASSES}
//         variables={{ sort: `${orderBy}: ${sortOrder}` }}
//       >
//         {({ loading, error, data, subscribeToMore }) => {
//           if (loading) return <p>Loading...</p>
//           if (error) {
//             console.error('Error loading classes', error)
//             const isNotAuthenticated = error.graphQLErrors.some(e => e.extensions.code === 'UNAUTHENTICATED')

//             if (isNotAuthenticated) {
//               return <p>Helaas! Je bent niet bevoegd om de lessen te bekijken</p>
//             }

//             return <p>Error :(</p>
//           }

//           // Use subscriptions to load modifications by other clients
//           // https://www.apollographql.com/docs/react/advanced/subscriptions.html#subscribe-to-more
//           subscribeToMore({
//             document: EXERCISE_CLASSES_SUBSCRIPTION,
//             variables: {},
//             updateQuery: (prev, { subscriptionData }) => {
//               if (!subscriptionData.data) return prev
//               const { class: classDoc, event } = subscriptionData.data.classModified

//               switch (event) {
//                 case 'create':
//                   if (prev.classes.find(c => c.id === classDoc.id)) return prev
//                   return { ...prev, classes: [...prev.classes, classDoc] }
//                 case 'update':
//                   return { ...prev, classes: prev.classes.map(c => (c.id === classDoc.id) ? classDoc : c) }
//                 case 'delete':
//                   return { ...prev, classes: prev.classes.filter(c => c.id !== classDoc.id) }
//                 default:
//                   throw new Error('Unknown event in EXERCISE_CLASSES_SUBSCRIPTION')
//               }
//             },
//           })



//           data.exerciseClasses.sort(sortReverse(sorters[orderBy], sortOrder === 1))


//         }}
//       </Query>
//     )
//   }
// }

// ListExerciseClassesComponent.propTypes = {
//   onRequestSort: PropTypes.func.isRequired,
//   onButtonClick: PropTypes.func.isRequired,
//   order: PropTypes.string.isRequired,
//   orderBy: PropTypes.string.isRequired,
// }

// const ExerciseClasses = withSnackbar(withRouter(withStyles(styles, { withTheme: true })(ListExerciseClassesComponent)))

// class ListExerciseClassesData extends React.Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       order: 'asc',
//       orderBy: 'id',
//     }
//   }

//   handleSort = (event, property) => {
//     const orderBy = property
//     let order = 'asc'

//     if (this.state.orderBy === property && this.state.order === 'asc') {
//       order = 'desc'
//     }

//     this.setState({ order, orderBy })
//   }

//   handleAddButtonClick = () => {
//     const { history } = this.props
//     history.push('/classes/create')
//   }

//   render() {
//     const { order, orderBy } = this.state

//     return (
//       <ExerciseClasses
//         onRequestSort={this.handleSort}
//         onButtonClick={this.handleAddButtonClick}
//         order={order}
//         orderBy={orderBy}
//       />
//     )
//   }
// }

// export default withRouter(ListExerciseClassesData)
