import React from 'react'
import createStore, { StateMapper, Store, BoundAction, ActionMap } from 'unistore'
import devtools from 'unistore/devtools'
import XMap from './utils/map'
import { IExerciseClass } from './graphql/exerciseClasses'

const initialStore: IStoreData = {
  exerciseClasses: {},
  classScheduleEntries: {},
  users: {},
  customers: {},
}
export interface IStoreData {
  exerciseClasses: Record<string, IExerciseClass>
  classScheduleEntries: Record<string, any>
  users: Record<string, any>
  customers: Record<string, any>
}
export type IStoreKey = keyof IStoreData
export type IStoreValue = IStoreData[IStoreKey]

export const store = (process.env.NODE_ENV === 'production')
  ? createStore(initialStore)
  : devtools(createStore(initialStore))

export type IStore = typeof store

export const StoreContext = React.createContext(store)
export const StoreProvider = StoreContext.Provider
export const useStore = () => React.useContext(StoreContext)

/**
 * @example
 * const actions = {
 *   increment(state) {
 *     return { count: state.count+1 }
 *   },
 * }
 *
 * @example
 * const actionFunctions = store => ({
 *   async getStuff(state) {
 *     const res = await fetch('/foo.json')
 *     return { stuff: await res.json() }
 *   },
 * }
 */
export type IActions<T extends ActionMap<IStoreData>> = ((store: Store<IStoreData>) => T) | T
export type IBoundActionsMap<T> = { [key in keyof T]: BoundAction }
// export function mapActions(actions: ((store: Store<IStoreData>) => Record<string, BoundAction>), store: Store<IStoreData>): Record<string, BoundAction>
// export function mapActions(actions: ActionFn<IStoreData>[], store: Store<IStoreData>): Record<string, BoundAction>
export function mapActions<T extends ActionMap<IStoreData>>(store: Store<IStoreData>, actions: IActions<T>): IBoundActionsMap<T> {
  if (typeof actions === 'function') actions = actions(store)

  const mapped: IBoundActionsMap<T> = {} as IBoundActionsMap<T>
  for (const key in actions) {
    const i = key as keyof T
    mapped[i] = store.action(actions[key])
  }

  return mapped
}

/**
 * Bind actions to the store and use in a Component
 */
export const useActions = <T extends ActionMap<IStoreData>>(actions: IActions<T>): IBoundActionsMap<T> => {
  const store = useStore()
  const boundActions = React.useMemo(() => actions ? mapActions<T>(store, actions) : {} as IBoundActionsMap<T>, [ store, actions ])
  return boundActions
}

/**
 * Bind store state and use in a Component
 * @link https://github.com/developit/unistore
 * @link https://github.com/developit/unistore/blob/master/src/index.js
 * @link https://github.com/developit/unistore/blob/master/src/integrations/react.js#L31
 * @link https://github.com/developit/unistore/blob/dd919e5d0b3f50460fb5425ba5152d6f99bd7a20/src/util.js#L2
 * @link https://github.com/developit/unistore/issues/136
 * @link https://github.com/mihar-22/preact-hooks-unistore/blob/master/src/index.js
 */
export const useStoreState = <R>(mapStateToProps: StateMapper<null, IStoreData, R>) => {
  const store = useStore()
  const stateRef = React.useRef(mapStateToProps(store ? store.getState() : initialStore, null))
  const [ , forceUpdate ] = React.useReducer(bool => !bool, true)

  const update = React.useCallback(() => {
    const mapped = mapStateToProps(store ? store.getState() : initialStore, null)

    for (let i in mapped) {
      if (mapped[i] !== stateRef.current[i]) {
        stateRef.current = mapped
        return forceUpdate()
      }
    }

    for (let i in stateRef) {
      if (!(i in mapped)) {
        stateRef.current = mapped
        forceUpdate()
      }
    }
  }, [ store, initialStore, stateRef, forceUpdate ])

  // Check for changes every change of the store
  React.useEffect(() => {
    const unsubscribe = store.subscribe(update)
    return () => unsubscribe()
  }, [ store, update ])

  return stateRef.current
}

export const actions = (store: IStore) => ({
  getExerciseClasses() {
    // fetch exerciseClasses
    const result: any[] = []
    return { exerciseClasses: new XMap(result) }
  }
})
