import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AppShell from './layouts/AppShell'
import {
  StylesProvider,
  ThemeProvider,
  // createMuiTheme,
  unstable_createMuiStrictModeTheme as createMuiTheme
} from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import nlLocale from 'date-fns/locale/nl'
import CssBaseline from '@material-ui/core/CssBaseline'
import { AuthProvider, useAuth, LoginStatus } from './components/providers/AuthProvider'
import { ApolloProvider } from 'react-apollo'
import apolloClient from './apollo/apolloClient'
import { SnackbarProvider } from 'notistack'
import { StoreProvider, store } from './store'
import Login from './pages/Login'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const theme = createMuiTheme({
  palette: {
    // type: 'dark',
    primary: {
      main: '#009FE3',
    },
    // secondary: {
    //   main: '#fff',
    // },
    text: {
      primary: '#009FE3',
      // secondary: '#000',

    },
  },
})

function AppChoice() {
  const { status } = useAuth()

  if (status === LoginStatus.Authenticated) {
    return <AppShell />
  }

  if (status === LoginStatus.Loading) {
    return <Paper style={{
      margin: 0,
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '300px',
      textAlign: 'center' as const,
    }}>
      <VpnKeyIcon style={{ fontSize: '3em' }} />
      <Typography>Check gegevens...</Typography>
    </Paper>
  }

  return <Login />
}

function App() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
          <ApolloProvider client={apolloClient}>
            <StoreProvider value={store}>
              <AuthProvider>
                <SnackbarProvider maxSnack={3}>
                  <Router>
                    <CssBaseline />

                    <AppChoice />

                  </Router>
                </SnackbarProvider>
              </AuthProvider>
            </StoreProvider>
          </ApolloProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
