import gql from 'graphql-tag'

export interface IExerciseClass {
  id: string
  name: string
  defaultDuration: number
  createdAt: string
  updatedAt: string
}

export const EXERCISE_CLASS_FRAGMENT = gql`
  fragment exerciseClassFragment on ExerciseClass {
    __typename
    id
    name
    defaultDuration
    createdAt
    updatedAt
  }
`

export const GET_EXERCISE_CLASSES = gql`
  query getExerciseClasses {
    exerciseClasses {
      ...exerciseClassFragment
    }
  }
  ${EXERCISE_CLASS_FRAGMENT}
`
export interface IGetExerciseClassesRes {
  exerciseClasses: IExerciseClass[]
}

export const EXERCISE_CLASSES_SUBSCRIPTION = gql`
  subscription onExerciseClassModified {
    exerciseClassModified {
      doc {
        ...exerciseClassFragment
      }
      event
    }
  }
  ${EXERCISE_CLASS_FRAGMENT}
`
export const GET_EXERCISE_CLASS = gql`
  query getExerciseClass($id: String!) {
    exerciseClass(find: { id: $id }) {
      ...exerciseClassFragment
    }
  }
  ${EXERCISE_CLASS_FRAGMENT}
`
export interface IGetExerciseClassRes {
  exerciseClass: IExerciseClass
}

export const ADD_EXERCISE_CLASS = gql`
  mutation createExerciseClass($input: ExerciseClassInput!) {
    createExerciseClass(input: $input) {
      ...exerciseClassFragment
    }
  }
  ${EXERCISE_CLASS_FRAGMENT}
`
export interface ICreateExerciseClassRes {
  createExerciseClass: IExerciseClass
}
export interface ICreateExerciseClassInput {
  input: {
    name: string
    defaultDuration: number
  }
}

export const EDIT_EXERCISE_CLASS = gql`
  mutation updateExerciseClass($id: String!, $input: ExerciseClassInput!) {
    updateExerciseClass(id: $id, input: $input) {
      ...exerciseClassFragment
    }
  }
  ${EXERCISE_CLASS_FRAGMENT}
`
export interface IUpdateExerciseClassRes {
  updateExerciseClass: IExerciseClass
}
export interface IUpdateExerciseClassInput extends ICreateExerciseClassInput {
  id: string
}

export const REMOVE_EXERCISE_CLASS = gql`
  mutation destroyExerciseClass(
    $id: String!
  ) {
    destroyExerciseClass(
      id: $id
    ) {
      ...exerciseClassFragment
    }
  }
  ${EXERCISE_CLASS_FRAGMENT}
`
export interface IDestroyExerciseClassRes {
  destroyExerciseClass: IExerciseClass
}
export interface IDestroyExerciseClassInput {
  id: string
}
