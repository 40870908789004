import React from 'react'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { randomString } from '../../utils/random'

export interface IPasswordInputProps {
  initialShowPassword?: boolean
  id?: string
  formControlClass: string
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  value: string
  error?: boolean
  helperText?: string
  disabled?: boolean
}

export default function PasswordInput({
  initialShowPassword = false,
  id,
  formControlClass,
  onChange,
  value,
  error = false,
  helperText = '',
  disabled = false,
}: IPasswordInputProps) {
  const [ showPassword, setShowPassword ] = React.useState(initialShowPassword)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  const [ inputId ] = React.useState(id || randomString())

  return (
    <FormControl
      className={formControlClass}
      error={error}
      aria-describedby={error ? `${inputId}-error-text` : ''}
    >
      <InputLabel htmlFor={inputId}>Password</InputLabel>
      <Input
        disabled={disabled}
        error={error}
        id={inputId}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={toggleShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && helperText ? <FormHelperText id={`${inputId}-error-text`}>{helperText}</FormHelperText> : null}
    </FormControl>
  )
}
