export default class XMap<K, V> extends Map<K, V> {
  constructor(iterator?: Iterable<readonly [K, V]>)
  constructor(entries?: readonly (readonly [K, V])[] | null | undefined)
  constructor()
  constructor(entries?: any) {
    super(entries)
  }

  public map = <T>(callback: (v: V, k: K, map: this) => T) => {
    const mapped = new XMap<K, T>()
    this.forEach(((value, key) => mapped.set(key, callback(value, key, this)) ))
    return mapped
  }

  public reduce = <T>(reducer: (acc: T, v: V, k: K, map: this) => T, initial: T) => {
    let acc: T = initial
    this.forEach(((value, key) => acc = reducer(acc, value, key, this)))
    return acc
  }

  public filter = (callback: (v: V, k: K, map: this) => boolean) => {
    const mapped = new XMap<K, V>()

    this.forEach(((value, key) => {
      if (callback(value, key, this)) mapped.set(key, value)
    }))

    return mapped
  }

  public toArrayEntries = () => Array.from(this.entries())
  public toArray = () => Array.from(this.values())
}

export function mapMap<K, V>(map: Map<K, V>, fn: (v: V, k: K, map: Map<K, V>) => V) {
  return new Map(Array.from(map, ([key, value]) => [key, fn(value, key, map)]))
}
