import React from 'react'

/**
 * Get a form value
 */
export const readValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const value = event.currentTarget.value
  if (typeof value === 'string') {
    return value.trim()
  }

  return value
}

export const setValue = (setter: React.Dispatch<React.SetStateAction<string>>) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setter(readValue(event))
