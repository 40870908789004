const toString = (str: any) => !str ? '' : str + ''
const toFloat = (n: number | string) => typeof n === 'string' ? parseFloat(n) : n

export type ISorter<T>  = (a: T, b: T) => number

/**
 * Sort function to sort strings
 */
export const sortOnString = (a: any, b: any) => toString(a).localeCompare(toString(b))

/**
 * Sort function to sort Dates
 */
export const sortOnDate = (a: string | number | Date, b: string | number | Date) => new Date(b).getTime() - new Date(a).getTime()

/**
 * Sort function to sort Numbers
 */
export const sortOnNumber = (a: number | string, b: number | string) => toFloat(b) - toFloat(a)

/**
 * Reverse the sort result
 * To sort ascending or descending
 */
export const sortReverse = <T>(sorter: ISorter<T>, reverse = false) =>
  (a: T, b: T) => reverse ? sorter(a, b) * -1 : sorter(a, b)

/**
 * Sort on a property of an object
 */
export const sortOn = <T, K extends keyof T = keyof T>(prop: K, sorter: ISorter<T[K]>) =>
  (a: T, b: T) => sorter(a[prop], b[prop])
