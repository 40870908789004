import React from 'react'
import { matchPath, Route, Switch, Link } from 'react-router-dom'
import Login from './pages/Login'
import CreateExerciseClass from './pages/exerciseClasses/createExerciseClass'
import ListExerciseClasses from './pages/exerciseClasses/listExerciseClasses'
import EditExerciseClass from './pages/exerciseClasses/editExerciseClass'
import ClassActivitiesList from './pages/classSchedule/ClassActivitiesList'
import Dashboard from './pages/dashboard/Dashboard'
import DashboardIcon from '@material-ui/icons/Dashboard'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIconTypeMap } from '@material-ui/core'

/**
 * Create a lazy loaded component with preload capabilities
 */
export const ReactLazyPreload = (
  importStatement: () => Promise<{ default: React.ComponentType<any> }>
): React.LazyExoticComponent<React.ComponentType<any>> => {
  const Component = React.lazy(importStatement) as any
  Component.preload = importStatement
  return Component
}

/**
 * Find the matching component for a route
 */
export const findComponentForRoute = (
  path: string,
  routes: readonly IRoute[]
): ((() => JSX.Element) & { preload?: () => void }) | null => {
  const matchingRoute = routes.find(route =>
    matchPath(path, {
      path: route.path,
      exact: route.exact
    })
  )

  return matchingRoute ? matchingRoute.component : null
}

/**
 * Preload a component if component is a lazy loaded component with preload capabilities
 */
export const preloadRouteComponent = (path: string) => {
  const component = findComponentForRoute(path, routesArray)

  if (component && component.preload) {
    component.preload()
  }
}

/**
 * Link component with preload when hovering over
 */
export const LinkWithPreload = ({ to, onPreload, ...rest }: { to: string, onPreload: () => void }) => {
  return (
    <Link
      to={to}
      onMouseEnter={() => preloadRouteComponent(to)}
      {...rest}
    />
  )
}

export interface IRoute {
  label: string
  path: string
  exact: boolean
  component: () => JSX.Element
  protected: boolean
  inSidebar: boolean
  Icon: (() => JSX.Element) | null | OverridableComponent<SvgIconTypeMap<{}, "svg">>
}

/**
 * Routes for the app
 */
export const routes: Readonly<Record<string, IRoute>> = Object.freeze({
  'login': { label: 'Inloggen', path: '/login', exact: true, component: Login, protected: false, inSidebar: false, Icon: null },
  'dashboard': { label: 'Dashboard', path: "/", exact: true, component: Dashboard, protected: true, inSidebar: true, Icon: DashboardIcon },
  'create-exerciseclass': { label: 'Les aanmaken', path: "/exercise-classes/new", exact: true, component: CreateExerciseClass, protected: true, inSidebar: false, Icon: FitnessCenterIcon },
  'update-exerciseclass': { label: 'Les wijzigen', path: "/exercise-classes/:id", exact: true, component: EditExerciseClass, protected: true, inSidebar: false, Icon: FitnessCenterIcon },
  'list-exerciseclasses': { label: 'Lessen', path: "/exercise-classes", exact: true, component: ListExerciseClasses, protected: true, inSidebar: true, Icon: FitnessCenterIcon },
  'class-schedule': { label: 'Lesrooster', path: "/class-schedule", exact: true, component: ClassActivitiesList, protected: true, inSidebar: true, Icon: ScheduleIcon },
})
export const routesArray = Object.freeze(Object.values(routes))

export default function Routes() {
  // return <Router>
  //   <React.Suspense fallback={"Loading"}>
  //     <Switch>
  //       {routesArray.map(route => (
  //         <Route
  //           key={route.path}
  //           exact={route.exact}
  //           path={route.path}
  //           component={route.component}
  //         />
  //       ))}
  //     </Switch>
  //   </React.Suspense>
  // </Router>

  return <Switch>
    {routesArray.map(route => (
      <Route
        key={route.path}
        exact={route.exact}
        path={route.path}
        component={route.component}
      />
    ))}
  </Switch>
}
