import { padLeft } from './string'
import { compose } from '@typed/compose'
import formatFns from 'date-fns/format'
import nlLocale from 'date-fns/locale/nl'

/**
 * Make sure a variable is a Date object
 */
export const toDate = (d: Date | number | string): Date => d instanceof Date ? d : new Date(d)

/**
 * Format a Date using NL locale
 */
export const format = (date: Date | number | string, format: string, options?: {
  locale?: globalThis.Locale | undefined;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
  firstWeekContainsDate?: number | undefined;
  useAdditionalWeekYearTokens?: boolean | undefined;
  useAdditionalDayOfYearTokens?: boolean | undefined;
} | undefined): string => {
  return formatFns(toDate(date), format, {
    locale: nlLocale,
    weekStartsOn: DaysOfTheWeek.Monday,
    ...options,
  })
}

export enum DaysOfTheWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

const padZero2 = (str: string | number) => padLeft(String(str), 2, '0')

/**
 * Get the last monday before the current weekday
 */
export const getLastWeekday = (d: Date | number | string = new Date(), afterWeekday = DaysOfTheWeek.Monday) => {
  const date = toDate(d)
  let weekday = date.getDay()
  if (weekday < afterWeekday) weekday += 7
  const diff = weekday - afterWeekday

  const lastMonday = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() - diff, 0, 0, 0, 0))
  return lastMonday
}

export const asDate = (date: Date | string) => format(date, 'd-M-yyyy')
export const asDateTime = (date: Date | string) => format(date, 'd-M-yyyy h:mm')

export const graphqlTimeToDate = (time: string): Date => {
  const [ hours, minutes = 0, seconds = 0 ] = (time + '').replace(/[^0-9:]/g, '').split(':')

  // This function is used only for the classSchedule
  // Here the time is not 19:00 in this timezone,
  // But 19:00 always
  const date = new Date()
  date.setUTCFullYear(1970)
  date.setUTCMonth(0)
  date.setUTCDate(1)
  date.setUTCHours(Number(hours))
  date.setUTCMinutes(Number(minutes))
  date.setUTCSeconds(Number(seconds))
  date.setUTCMilliseconds(0)

  return date
}

/**
 * Create a human readable time from a GraphQL time string
 * @param {string} time GraphQL UTC Time string (16:25:00Z)
 * @return {string} Human readable time string (16:25)
 */
export const graphqlTimeToTime = (time: string): string => {
  const date = graphqlTimeToDate(time)

  return [
    date.getUTCHours(),
    date.getUTCMinutes(),
  ].map(t => padZero2(String(t))).join(':')
}

/**
 * Time to GraphQL UTC Time
 * @param {string} time Hour:minutes time (12:12)
 * @return {string} GraphQL UTC time (12:12:00Z)
 */
export const timeToGraphqlTime = (time: string): string => {
  const date = graphqlTimeToDate(time)

  return [
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  ].map(t => padZero2(String(t))).join(':') + 'Z'
}

/**
 * Directly from seconds to Graphql UTC time
 * @param {int} seconds Number of seconds from midnight
 * @returns {string} GraphQL UTC time string
 */
export const secondsToGraphqlTime = compose(
  timeToGraphqlTime,
  secondsToTime
)

/**
 * Directly from Graphql UTC time to seconds
 * @param {string} time GraphQL UTC time string
 * @returns {int} Number of seconds from midnight
 */
export const graphqlTimeToSeconds = compose(
  timeToSeconds,
  graphqlTimeToTime
)

/**
 * Calculate number of seconds from midnight for time
 *
 * @param {string} time A time string (22:35) or (22:35:22)
 * @return {number} Number of seconds from midnight
 */
export function timeToSeconds (time: string): number {
  const parts = time.replace(/[^0-9:]/g, '').split(':')

  const [ hours, minutes = '0', seconds = '0' ] = parts
  return (parseInt(hours, 10) * (60 * 60)) +
    (parseInt(minutes, 10) * 60) +
    parseInt(seconds, 10)
}

/**
 * Get a time string for a number of seconds from midnight
 *
 * @param {number} secondsFromMidnight Number of seconds from midnight
 * @return {string} A time string (22:35:22)
 */
export function secondsToTime (secondsFromMidnight: number) {
  let seconds = secondsFromMidnight % 86400

  const hours = Math.floor(seconds / 3600)
  seconds %= 3600

  const minutes = Math.floor(seconds / 60)
  seconds %= 60

  return [ padZero2(hours), padZero2(minutes), padZero2(seconds) ].join(':')
}
