import React from 'react'

export interface SimpleErrorProps {
  error: Error
}

export default function SimpleError({ error }: SimpleErrorProps) {
  return (
    <>
     <h1>Fout</h1>
     <div>Er is iets mis gegaan ☹</div>
     <pre>{String(error)}</pre>
   </>
 )
}
