import gql from 'graphql-tag'

export interface IClassActivity {
  id: string
  from: string
  to: string
  exerciseClass: {
    id: string
  }
  room: {
    id: string
  }
  instructor: {
    id: string
  }
  description1: string
  description2: string
}

export const CLASS_ACTIVITY_FRAGMENT = gql`
  fragment classActivityFragment on ClassActivity {
    __typename
    id
    from
    to
    exerciseClass {
      id
      name
    }
    room {
      id
      name
      location {
        id
        name
      }
    }
    instructor {
      id
      fullName
    }
    description1
    description2
  }
`

export const GET_CLASS_ACTIVITIES = gql`
  query getclassActivities($find: ClassActivityFind!, $sort: String) {
    classActivities(find: $find, sort: $sort) {
      ...classActivityFragment
    }
  }
  ${CLASS_ACTIVITY_FRAGMENT}
`
export interface IGetclassActivitiesRes {
  classActivities: IClassActivity[]
}

export const CLASS_ACTIVITYES_SUBSCRIPTION = gql`
  subscription onclassActivityModified {
    classActivityModified {
      doc {
        ...classActivityFragment
      }
      event
    }
  }
  ${CLASS_ACTIVITY_FRAGMENT}
`
export const GET_CLASS_ACTIVITY = gql`
  query getclassActivity($id: String!) {
    classActivity(find: { id: $id }) {
      ...classActivityFragment
    }
  }
  ${CLASS_ACTIVITY_FRAGMENT}
`
export interface IGetclassActivityRes {
  classActivity: IClassActivity
}

export const ADD_CLASS_ACTIVITY = gql`
  mutation createclassActivity($input: classActivityInput!) {
    createclassActivity(input: $input) {
      ...classActivityFragment
    }
  }
  ${CLASS_ACTIVITY_FRAGMENT}
`
export interface ICreateclassActivityRes {
  createclassActivity: IClassActivity
}
export interface ICreateclassActivityInput {
  input: {
    name: string
    defaultDuration: number
  }
}

export const EDIT_CLASS_ACTIVITY = gql`
  mutation updateclassActivity($id: String!, $input: classActivityInput!) {
    updateclassActivity(id: $id, input: $input) {
      ...classActivityFragment
    }
  }
  ${CLASS_ACTIVITY_FRAGMENT}
`
export interface IUpdateclassActivityRes {
  updateclassActivity: IClassActivity
}
export interface IUpdateclassActivityInput extends ICreateclassActivityInput {
  id: string
}

export const REMOVE_CLASS_ACTIVITY = gql`
  mutation destroyclassActivity(
    $id: String!
  ) {
    destroyclassActivity(
      id: $id
    ) {
      ...classActivityFragment
    }
  }
  ${CLASS_ACTIVITY_FRAGMENT}
`
export interface IDestroyclassActivityRes {
  destroyclassActivity: IClassActivity
}
export interface IDestroyclassActivityInput {
  id: string
}
