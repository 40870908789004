import React from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { Redirect, useParams } from 'react-router'

import { getErrorFields } from '../../utils/apolloError'
import { routes } from '../../routes'
import { GET_EXERCISE_CLASS, EDIT_EXERCISE_CLASS } from '../../graphql/exerciseClasses'
import ExerciseClassFrom, { IExerciseClassData } from './ExerciseClassForm'
import { noop } from '../../utils/fn'

export default function EditExerciseClass() {
  const { id } = useParams()

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
    // subscribeToMore,
  } = useQuery(GET_EXERCISE_CLASS, { variables: { id } })

  const [ editExerciseClass, { loading, error } ] = useMutation(EDIT_EXERCISE_CLASS, { errorPolicy: 'all' })

  if (!id) return <Redirect to={routes['list-exerciseclasses'].path} />

  const handleSubmit = (data: IExerciseClassData) => {
    editExerciseClass({ variables: { id: queryData?.exerciseClass.id, input: data } }).catch(noop)
  }

  if (queryLoading) return <div>Les laden...</div>
  if (queryError) return <div>Error bij het laden van de les.</div>

  return (
    <ExerciseClassFrom
      isLoading={loading}
      handleSubmit={handleSubmit}
      errors={getErrorFields(error)}
      initialData={queryData?.exerciseClass}
    />
  )
}
