import React from 'react'
import { useMutation } from 'react-apollo'
import { Redirect } from 'react-router'
import { useSnackbar } from 'notistack'

import { getErrorFields } from '../../utils/apolloError'
import {
  GET_EXERCISE_CLASSES,
  IGetExerciseClassesRes,
  ADD_EXERCISE_CLASS,
  ICreateExerciseClassRes,
  ICreateExerciseClassInput
} from '../../graphql/exerciseClasses'
import { noop } from '../../utils/fn'
import { routes } from '../../routes'
import ExerciseClassFrom, { IExerciseClassData } from './ExerciseClassForm'

export default function CreateExerciseClass() {
  const { enqueueSnackbar } = useSnackbar()

  const [createExerciseClass, { loading, data, error }] = useMutation<ICreateExerciseClassRes, ICreateExerciseClassInput>(ADD_EXERCISE_CLASS, {
    errorPolicy: 'all',
    update: (cache, { data: resultData }) => {
      const createExerciseClass = resultData?.createExerciseClass
      const cacheData = cache.readQuery<IGetExerciseClassesRes>({ query: GET_EXERCISE_CLASSES })

      if (cacheData?.exerciseClasses) {
        cache.writeQuery({
          query: GET_EXERCISE_CLASSES,
          data: { exerciseClasses: [ ...cacheData.exerciseClasses, createExerciseClass ] },
        })
      }
    },
    onCompleted: (data: ICreateExerciseClassRes) => {
      enqueueSnackbar(`Les '${data?.createExerciseClass?.name}' succesvol toegevoegd!`, { variant: 'success' })
    },
    onError: () => enqueueSnackbar(`Er is iets fout gegaan. Les kon niet worden toegevoegd.`, { variant: 'error' }),
  })

  const handleSubmit = (formData: IExerciseClassData) => {
    createExerciseClass({ variables: { input: formData } }).catch(noop)
  }

  if (data?.createExerciseClass?.id) return <Redirect to={routes['update-exerciseclass'].path.replace(':id', data.createExerciseClass.id)} />

  return (
    <ExerciseClassFrom
      isLoading={loading}
      handleSubmit={handleSubmit}
      errors={getErrorFields(error)}
    />
  )
}
